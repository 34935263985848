body {
    font-family: "Franklin Gothic", "Arial Bold";
}

.background {
    position: relative;
}

.wrapper {
    position: relative;
}

.content {
    position: relative;
    background: #FFF;
    background: rgba(255, 255, 255, 1);
    width: 70%;
    margin: -16px;
    margin-left: auto;
    margin-right: auto;
}

.navi {
    position: relative;
    background: #FFF;
    background: rgba(255, 255, 255, 1);
    width: 50%;
    margin: auto;
}

img {
    max-width: 100%;
    height: auto;
    width: auto;
  }